import React from 'react';
import { Link } from 'gatsby';

import Icon from '@components/_ui/Icon';

const CreateCallout = () => (
    <div className="flow--medium">
        <div className="cluster">
            <div className="create-callout">
                <div className="create-callout__content">
                    <h2 className="combo-heading">
                        <span className="text-subtitle">Create your own perfect pergola</span>
                        <span className="text-paragraph text-color--steelbook">Our bespoke Pergola Planner will let you design your pergola to meet your own specification, dimensions and requirements!</span>
                    </h2>
                    <p className="text-paragraph">We are extremely excited to announce a totally unique design service: ‘Pergola Planner’. For the first time you can now develop your own bespoke pergola or arbour for the garden - custom made to your own requirements!</p>
                </div>
                <div className="create-callout__features cluster">
                    <div className="feature-blocks">
                        <div className="bracket feature-block">
                            <Icon id="upload" className="feature-block__icon bracket__fixed" />
                            <div className="bracket__flex">
                                <h4 className="text-label">Upload your sketch</h4>
                                <span className="text-metadata">Send us a picture of your perfect pergola or inspiration behind your idea.</span>
                            </div>
                        </div>
                        <div className="bracket feature-block">
                            <Icon id="design" className="feature-block__icon bracket__fixed" />
                            <div className="bracket__flex">
                                <h4 className="text-label">We work with you</h4>
                                <span className="text-metadata">We'll guide you through the design process every step of the way.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-align--center push--bottom--small">
            <Link to="/planner/" className="button" title="Design your perfect pergola">
                <span>Create your own bespoke pergola plan</span>
                <Icon id="arrow-right" className="button__icon" />
            </Link>
        </div>
    </div>

);

export default CreateCallout;
