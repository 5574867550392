import React from 'react';

import Carousel from '@components/_ui/Carousel';
import ModuleCard from '@components/modules/ModuleCard';
import modules from '@data/json/modules.json';

const ModuleSelector = () => (
    <div className="module-selector">
        <Carousel
            options={
                {
                    initialIndex: 0,
                }
            }
            maxWidth={1152}
            className="module-selector__listing"
            itemClassName="reel__item--module"
        >
            { modules.map((module) => <ModuleCard data={module} className="module-selector__item" key={module.slug} />) }
        </Carousel>
    </div>
);

export default ModuleSelector;
