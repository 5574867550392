import React from 'react';
import { Link } from 'gatsby';

const ModuleCard = ({ data, className }) => {
    if (!data) {
        return false;
    }

    return (
        <div className={`module-card module-card--zoom ${className}`}>
            <div className="flow">
                <img src={data.imageUrl} alt={data.title} className="module-card__image" />
                <div className="flow--xsmall">
                    <div className="flex--justify">
                        <h4 className="text-subheading">{data.title}</h4>
                        <span className="text-price">From £{data.price}</span>
                    </div>
                    <p className="text-metadata">{data.description}</p>
                </div>
            </div>

            <Link to={`/shop/customise/?module=${data.slug}`} className="button button--fullwidth card__hitbox" title="Configure and buy a pergola">
                <span>Configure &amp; Buy</span>
            </Link>
        </div>
    );
};

ModuleCard.defaultProps = {
    data: null,
    className: '',
};

export default ModuleCard;
