import { useEffect, useState } from 'react';

const useWatchViewportWidth = (maxWidth) => {
    const [isLessThanWidth, setIsLessThanWidth] = useState(false);

    useEffect(() => { // eslint-disable-line
        let timeout = null;

        if (typeof window !== 'undefined') {
            const checkWidth = () => {
                window.clearTimeout(timeout);
                timeout = window.setTimeout(() => setIsLessThanWidth((window.innerWidth < maxWidth)), 150);
            };

            window.addEventListener('resize', checkWidth);
            checkWidth();


            return () => {
                window.clearTimeout(timeout);
                window.removeEventListener('resize', checkWidth);
            };
        }
    }, []);

    return [isLessThanWidth];
};

export default useWatchViewportWidth;
