import React, { useEffect } from 'react';

import useWatchViewportWidth from '@hooks/useWatchViewportWidth';

const defaultFlickityOptions = {
    wrapAround: true,
    imagesLoaded: true,
    groupCells: false,
    pageDots: false,
    prevNextButtons: false,
    selectedAttraction: 0.2,
    friction: 1.6,
    initialIndex: 2,
    arrowShape: 'M56.78125,33.2083333 C55.7604167,32.1822917 54.0989583,32.1822917 53.0833333,33.2083333 L38.4635417,48.1302083 C37.4427083,49.1666667 37.4427083,50.8385417 38.4635417,51.8645833 L53.0833333,66.78125 C54.0989583,67.8177083 55.7604167,67.8125 56.78125,66.78125 C57.8020833,65.7552083 57.8020833,64.0833333 56.78125,63.046875 L44.7916667,50 L56.78125,36.9479167 C57.8020833,35.9166667 57.8020833,34.25 56.78125,33.2083333 Z M50,0 C22.3854167,0 0,22.3854167 0,50 C0,77.6197917 22.3854167,100 50,100 C77.6145833,100 100,77.6197917 100,50 C100,22.3854167 77.6145833,0 50,0 Z M50,93.5104167 C25.9635417,93.5104167 6.48958333,74.03125 6.48958333,50 C6.48958333,25.96875 25.9635417,6.48958333 50,6.48958333 C74.0260417,6.48958333 93.5104167,25.96875 93.5104167,50 C93.5104167,74.03125 74.0260417,93.5104167 50,93.5104167 Z'
};

const Carousel = ({
    children, options, className, itemClassName, maxWidth
}) => {
    const [isLessThanWidth] = useWatchViewportWidth(maxWidth);

    const carouselRef = React.createRef();

    let flickity;

    defaultFlickityOptions.initialIndex = Math.ceil(children.length / 2);

    useEffect(() => {
        const flickityOptions = { ...defaultFlickityOptions, ...(options || {}) };

        if (typeof window !== 'undefined') {
            const Flickity = require('flickity-imagesloaded'); // eslint-disable-line

            if (!maxWidth || (maxWidth && isLessThanWidth)) {
                flickity = new Flickity(carouselRef.current, flickityOptions);
            }
        }

        return () => {
            if (flickity) {
                flickity.destroy();
            }
        };
    }, [options, isLessThanWidth]);

    return (
        <div ref={carouselRef} className={`reel carousel ${className}`}>

            {children
                && children.map((child, index) => (
                    <div className={`reel__item ${itemClassName}`} key={index}>
                        {child}
                    </div>
                ))}

        </div>
    );
};

Carousel.defaultProps = {
    className: '',
    itemClassName: '',
    children: undefined,
    options: undefined,
    maxWidth: null,
};

export default Carousel;
